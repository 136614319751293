/** @format */

import { useEffect, useMemo, useState } from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Popover from "@mui/material/Popover";
import reducer from "./store";
import APIService from "src/app/services/APIService";
import DataHandler from "src/app/handlers/DataHandler";
//import { selectNotifications } from "./store/dataSlice";
//import { toggleNotificationPanel } from "./store/stateSlice";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Button, Link } from "@mui/material";
import { locale } from "app/configs/navigation-i18n";
import { useNavigate } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  borderRadius: 0,
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

function NotificationPanelToggleButton(props) {
  // const notificationsAlert = useSelector(selectNotifications);
    const [headerLoad] = useSelector((state) => [state.headerLoad.headerLoad]);
  //const dispatch = useDispatch()

  const navigate = useNavigate();
  const [selectLocale] = useSelector((state) => [state.locale.selectLocale]);
  const [selectedLang, setSelectedLang] = useState(locale.en);
  const user_id = DataHandler.getFromSession("user_id");
  const [notifications, setNotification] = useState([]);
  const [menu, setMenu] = useState(null);  
  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };
  useEffect(() => {
    if (selectLocale == "ko") {
      setSelectedLang(locale.ko);
    } else {
      setSelectedLang(locale.en);
    }
  }, [selectLocale]);

  const langMenuClose = () => {
    setMenu(null);
  };

  function handleLanguageChange() {
    langMenuClose();
  }
  function navigateToNotification() {
    getNotifications();
    navigate("/agent/transactionHistory");
  }

  const pollingInterval = 600000;

  useEffect(() => {
    getNotifications();
    setInterval(getNotifications, pollingInterval);
  }, []);

    useEffect(() => {
      console.log("headerLoad changed")
    getNotifications();
    setInterval(getNotifications, pollingInterval);
  }, [headerLoad]);



  useEffect(() => {
    if (notifications.length > 0) {
      const audio = new Audio("assets/sound/noti1.mp3");

      const playAudio = () => {
        audio.play().catch((error) => { });
      };
      playAudio();
    }
  }, [notifications]);

  const getNotifications = async () => {
    await APIService({
      url: `${process.env.REACT_APP_R_SITE_API}/request-list-not-approved?user_id=${user_id}`,
      method: "GET",
    })
      .then((res) => {
        setNotification(res.data.data);
      })
      .catch((e) => { })
      .finally(() => { });
  };

  const readNofication = async (request_id, type) => {
    await APIService({
      url: `${process.env.REACT_APP_R_SITE_API}/user/read_notification/${request_id}`,
      data: {
        type: type,
      },
      method: "PUT",
    })
      .then((res) => {
        // setNotification(res.data.data);
      })
      .catch((e) => { })
      .finally(() => { });
  };

  const readAllNofication = async () => {
    await APIService({
      url: `${process.env.REACT_APP_R_SITE_API}/user/read_all_notification/${user_id}`,
      method: "PUT",
    })
      .then((res) => {
        // setNotification(res.data.data);
        getNotifications();
      })
      .catch((e) => { })
      .finally(() => { });
  };

  return (
    <>
      <IconButton
        key={"icon-but"}
        className="Notification_btn"
        onClick={langMenuClick}
        size="large">
        <Badge
          key={"badge"}
          color="secondary"
          variant={
            notifications &&
              notifications.reduce(
                (acc, item) => acc + (item.notification_read ? 0 : 1),
                0
              ) > 0
              ? `dot`
              : ""
          }
          invisible={notifications.length === 0}>
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.58203 23C7.60125 23 5.98828 21.3881 5.98828 19.4062C5.98828 19.0095 6.31028 18.6875 6.70703 18.6875C7.10378 18.6875 7.42578 19.0095 7.42578 19.4062C7.42578 20.5956 8.39371 21.5625 9.58203 21.5625C10.7703 21.5625 11.7383 20.5956 11.7383 19.4062C11.7383 19.0095 12.0603 18.6875 12.457 18.6875C12.8538 18.6875 13.1758 19.0095 13.1758 19.4062C13.1758 21.3881 11.563 23 9.58203 23Z" fill="#748191"/>
          <path d="M17.4895 20.25H1.67702C0.752262 20.25 3.18493e-07 19.4977 3.18493e-07 18.573C-0.000148989 18.3308 0.0521993 18.0914 0.153441 17.8713C0.254682 17.6513 0.402411 17.4558 0.586455 17.2983C0.610489 17.2773 0.636319 17.2581 0.663137 17.2408C2.06991 16.0131 2.875 14.247 2.875 12.382V9.70827C2.875 6.00923 5.88512 3 9.58327 3C9.73664 3 9.90244 3.00279 10.0559 3.02875C10.4477 3.09389 10.7123 3.46485 10.647 3.85576C10.5819 4.24676 10.2043 4.5114 9.82001 4.44608C9.74333 4.43364 9.6591 4.4375 9.58327 4.4375C6.67773 4.4375 4.3125 6.80169 4.3125 9.70827V12.382C4.3125 14.6974 3.29754 16.8882 1.53049 18.3917C1.51611 18.4033 1.50367 18.4138 1.48822 18.4243C1.45492 18.4667 1.43703 18.5191 1.4375 18.573C1.4375 18.7032 1.54684 18.8125 1.67702 18.8125H17.4895C17.6199 18.8125 17.7292 18.7032 17.7292 18.573C17.7292 18.5058 17.7043 18.457 17.6775 18.4243C17.6631 18.4138 17.6506 18.4033 17.6362 18.3917C15.8681 16.8872 14.8542 14.6974 14.8542 12.382V11.3376C14.8542 10.9408 15.1762 10.6188 15.573 10.6188C15.9697 10.6188 16.2917 10.9408 16.2917 11.3376V12.382C16.2917 14.248 17.0977 16.0151 18.5064 17.2437C18.5322 17.2609 18.5571 17.2792 18.5801 17.2994C18.7643 17.4565 18.9121 17.6518 19.0134 17.8717C19.1147 18.0916 19.167 18.3309 19.1667 18.573C19.1667 19.4977 18.4145 20.25 17.4895 20.25Z" fill="#748191"/>
          <path d="M16.2917 9.58327C13.6496 9.58327 11.5 7.43385 11.5 4.79173C11.5 2.1496 13.6496 0 16.2917 0C18.9339 0 21.0833 2.1496 21.0833 4.79173C21.0833 7.43385 18.9339 9.58327 16.2917 9.58327ZM16.2917 1.4375C14.442 1.4375 12.9375 2.94202 12.9375 4.79173C12.9375 6.64125 14.442 8.14577 16.2917 8.14577C18.1413 8.14577 19.6458 6.64125 19.6458 4.79173C19.6458 2.94202 18.1413 1.4375 16.2917 1.4375Z" fill="#748191"/>
          </svg>
        </Badge>
      </IconButton>

      {notifications.length > 0 && notifications !== "" ?
      (
        <Popover
          key={"popover"}
          className="notification_popover"
          open={Boolean(menu)}
          anchorEl={menu}
          onClose={langMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <Box key={"box-1"} sx={{ width: "100%" }}>
            <Button
              style={{ width: "100%", textAlign: "left", padding: "8px 16px" }}
              onClick={() => {
                readAllNofication();
              }}>
              {selectedLang.mark_all_as_read}
            </Button>
            <Stack key={"stack-1"}>
              {notifications
                .sort((a, b) => {
                  const dateA = new Date(a.created_at);
                  const dateB = new Date(b.created_at);
                  return dateB - dateA;
                })
                .map((noti, index) => (
                  <Link
                    key={index}
                    style={{
                      textDecoration: "none",
                      underline: "none",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                    onClick={navigateToNotification}>
                    <ul key={index} className="notification_card">
                      <li>
                        <span key={index}>{index + 1}</span>
                        <p
                          key={index}
                          className={`${noti.notification_read ? "read" : "unread"
                            }-notification`}
                          onClick={() => {
                            readNofication(
                              noti?.transaction_type == "deposit" ||
                                noti?.transaction_type == "withdraw"
                                ? noti?.transaction_id
                                : noti?.request_id,
                              noti?.transaction_type == "deposit" ||
                                noti?.transaction_type == "withdraw"
                                ? "withdraw_deposite"
                                : "r_request"
                            );
                          }}>
                          {selectLocale == "ko" ? (
                            noti?.transaction_type == "deposit" ||
                              noti?.transaction_type == "withdraw" ? (
                              <>
                                {`${noti?.to_user_name}  ${noti?.transaction_type == "deposit"
                                    ? `님의 계정에 ${Number(
                                      noti?.amount || 0
                                    ).toLocaleString()} pot 출금`
                                    : `님의 계정에서 ${Number(
                                      noti?.amount || 0
                                    ).toLocaleString()} pot 입금`
                                  }`}
                              </>
                            ) : (
                              <>
                                {noti.userDetails[0]?.id}{" "}
                                {selectedLang.has_requested} {noti.point_amount}{" "}
                                {selectedLang.r_points}
                              </>
                            )
                          ) : noti?.transaction_type == "deposit" ||
                            noti?.transaction_type == "withdraw" ? (
                            <>
                              {`${Number(
                                noti?.amount || 0
                              ).toLocaleString()} pot ${noti?.transaction_type == "deposit"
                                  ? `${selectedLang.deposit_to} ${noti?.to_user_name}`
                                  : `${selectedLang.withdrawan_from} ${noti?.to_user_name}`
                                }'${selectedLang.account}`}
                            </>
                          ) : (
                            <>
                              {noti.userDetails[0]?.id}{" "}
                              {selectedLang.has_requested}{" "}
                              {Number(noti.point_amount).toLocaleString()}
                              {" pot"} {selectedLang.r_points}
                            </>
                          )}
                        </p>
                      </li>
                    </ul>
                  </Link>
                ))}
            </Stack>
          </Box>
        </Popover>
      )
      :
      (
        <Popover
          key={"popover"}
          className="notification_popover"
          open={Boolean(menu)}
          anchorEl={menu}
          onClose={langMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <Box key={"box-1"} sx={{ width: "100%" }}>
            <Button
              style={{ width: "100%", textAlign: "left", padding: "8px 16px" }}
              // onClick={() => {
              //   readAllNofication();
              // }}
              >
              {selectedLang.noNotification}
            </Button>
          </Box>
        </Popover>
      )
    }
    </>
  );
}

NotificationPanelToggleButton.defaultProps = {
  children: (
    <FuseSvgIcon key={"fuse-icone"}>heroicons-outline:bell</FuseSvgIcon>
  ),
};

export default withReducer(
  "notificationPanel",
  reducer
)(NotificationPanelToggleButton);
