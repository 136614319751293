/** @format */

import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";
import ko from "./navigation-i18n/ko";
import jp from "./navigation-i18n/jp";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);
i18next.addResourceBundle("ko", "navigation", ko);
i18next.addResourceBundle("jp", "navigation", jp);
import { menusIcons } from './menusIcons'

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    //subtitle: "Unique dashboard designs",
    type: "group",
    icon: menusIcons?.dashboard,
    translate: "DASHBOARD",
    url: "/dashboard",
    access: ["admin", "user", "cs"],
  },
  // {
  //   id: "ggr",
  //   title: "GGR",
  //   //subtitle: "Unique dashboard designs",
  //   type: "group",
  //   icon:menusIcons?.GGR,
  //   translate: "GGR",
  //   url: "/ggr",
  //   access: ["admin"],
  // },
  {
    id: "mypage",
    title: "My Page",
    //subtitle: "Unique dashboard designs",
    type: "group",
    icon: menusIcons?.mypage,
    translate: "MYPAGE",
    url: "/mypage",
    access: ["admin", "user", "cs"],
  },
  {
    id: "systemmanagement",
    title: "System Management",
    //subtitle: "Unique dashboard designs",
    type: "group",
    icon: menusIcons?.serviceDesk,
    translate: "SYSTEMMANAGEMENT",
    url: "/systemManagement",
    access: ["admin"],
  },
  // {
  //   id: "servicedesk",
  //   title: "Service Desk",
  //   //subtitle: "Unique dashboard designs",
  //   type: "group",
  //   icon: menusIcons?.serviceMange,
  //   translate: "SERVICEDESK",
  //   url: "/serviceDesk",
  //   access: ["admin", "user", "cs"],
  // },
  {
    id: "divider-1",
    type: "divider",
  },
  {
    id: "SolutionManagement_admmin",
    title: "Solution Management",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.solutionManagement,
    translate: "Solution_Management",
    access: ["admin"],
    children: [
      {
        id: "usercallmanagement",
        title: "User Call Management",
        type: "item",
        translate: "USERCALLMANAGEMENT",
        url: "/usercallManagement",
      },
      {
        id: "providermanagement",
        title: "Betting Management",
        type: "item",
        translate: "PROVIDERMANAGEMENT",
        url: "/providerManagement",
      },
      {
        id: "winmanagement",
        title: "Winning Management",
        type: "item",
        translate: "WINMANAGEMENT",
        url: "/max-win-management",
      },
      {
        id: "gamemanagement",
        title: "Game Management",
        type: "item",
        translate: "GAMEMANAGEMENT",
        url: "/gameManagement",
      },
      {
        id: "vendormanagement",
        title: "Vendor Management",
        type: "item",
        translate: "VENDORMANAGEMENT",
        url: "/vendorlistManagement",
      },
      {
        id: "brandingmanagement",
        title: "Branding Management",
        type: "item",
        translate: "BRANDINGMANAGEMENT",
        url: "/brandingManagement",
      },
      {
        id: "userMoneyManagement",
        title: "User Money Management",
        type: "item",
        translate: "USERMONEYMANAGEMENT",
        url: "/user/Money",
      },
      {
        id: "gamelistmanagement",
        title: "Game Management",
        type: "item",
        translate: "GAMELISTMANAGEMENT",
        url: "/gamelistmanagement",
      },
      {
        id: "userbettinglist",
        title: "User Betting List",
        type: "item",
        translate: "USERBETMANAGEMENT",
        url: "/userBettingManagemnent",
      },
      {
        id: "aggregatormanagement",
        title: "Aggregator Management",
        type: "item",
        translate: "AGGREGATORMANAGEMENT",
        url: "/aggregatormanagement",
      },
      {
        id: "behaviorlog",
        title: "Behavior Log",
        type: "item",
        translate: "BEHAVIORLOG",
        url: "/behaviorlog",
      },
      {
        id: "investaccount",
        title: "Invest Account",
        type: "item",
        translate: "INVESTACCOUNT",
        url: "/investaccount",
      },
      // {
      //   id: "investaccount",
      //   title: "Invest Account",
      //   type: "item",
      //   translate: "INVESTACCOUNT",
      //   url: "/investAccount",
      // },
      // {
      //   id: "userbettinglist",
      //   title: "User Betting List",
      //   type: "item",
      //   translate: "USERBETTINGLIST",
      //   url: "/userbettinglist",
      // },
    ],
  },
  {
    id: "SolutionManagement_cs",
    title: "Solution Management",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.solutionManagement,
    translate: "Solution_Management",
    access: ["cs"],
    children: [
      {
        id: "usercallmanagement",
        title: "User Call Management",
        type: "item",
        translate: "USERCALLMANAGEMENT",
        url: "/usercallManagement",
      },
      {
        id: "providermanagement",
        title: "Betting Management",
        type: "item",
        translate: "PROVIDERMANAGEMENT",
        url: "/providerManagement",
      },
      {
        id: "vendormanagement",
        title: "Vendor Management",
        type: "item",
        translate: "VENDORMANAGEMENT",
        url: "/vendorlistManagement",
      },
      {
        id: "csgamemanagement",
        title: "Game Management",
        type: "item",
        translate: "GAMELISTMANAGEMENT",
        url: "/gamelistmanagement",
      },
      {
        id: "aggregatormanagement",
        title: "Aggregator Management",
        type: "item",
        translate: "AGGREGATORMANAGEMENT",
        url: "/aggregatormanagement",
      },
      {
        id: "investaccount",
        title: "Invest Account",
        type: "item",
        translate: "INVESTACCOUNT",
        url: "/investaccount",
      },
    ],
  },
  {
    id: "SolutionManagement_user",
    title: "Solution Management",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.solutionManagement,
    translate: "Solution_Management",
    access: ["user"],
    children: [
      {
        id: "usercallmanagement",
        title: "User Call Management",
        type: "item",
        translate: "USERCALLMANAGEMENT",
        url: "/usercallManagement",
      },
      {
        id: "providermanagement",
        title: "Betting Management",
        type: "item",
        translate: "PROVIDERMANAGEMENT",
        url: "/providerManagement",
      },
      {
        id: "winmanagement",
        title: "Winning Management",
        type: "item",
        translate: "WINMANAGEMENT",
        url: "/max-win-management",
      },
      {
        id: "usergamemanagement",
        title: "Game Management",
        type: "item",
        translate: "GAMEMANAGEMENT",
        url: "/gameManagement",
      },
      {
        id: "userbettinglist",
        title: "User Betting List",
        type: "item",
        translate: "USERBETMANAGEMENT",
        url: "/userBettingManagemnent",
      },
    ],
  },
  {
    id: "AdminManagementADMIN",
    title: "Admin Management",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.Admin,
    translate: "ADMINMANAGEMENT",
    access: ["admin"],
    children: [
      {
        id: "apiErrorLog",
        title: "Provider API error log",
        type: "item",
        translate: "PROVIDER_APIERRORLOG",
        url: "/agent/api-errors",
      },
      {
        id: "ggrLimit",
        title: "Balance Limit",
        type: "item",
        translate: "BALANCELIMIT",
        url: "/agent/balance-limits",
      },
      {
        id: "gp",
        title: "Generate POT",
        type: "item",
        translate: "GENERATEPOT",
        url: "/agent/generate-pot",
      },
      {
        id: "pdc",
        title: "Pre Data Control",
        type: "item",
        translate: "PREDATACONTROL",
        url: "/agent/preDataControl",
      },
      {
        id: "idd",
        title: "Invest Details",
        type: "item",
        translate: "investDetails",
        url: "/investaccountdetails",
      },
      {
        id: "ec",
        title: "Env Controller Management",
        type: "item",
        translate: "ENVCONTROLLER",
        url: "/envcontroller",
      },
    ],
  },
  {
    id: "pot(egg)",
    title: "Pot Charging/Payment",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.potHistory,
    translate: "POT",
    access: ["admin"],
    children: [
      {
        id: "transactionhistory1",
        title: "Transaction History",
        type: "item",
        translate: "TRANSACTIONHISTORYAGENT",
        url: "/agent/transactionHistory",
      },
      {
        id: "transactionhistory2",
        title: "Transaction History",
        type: "item",
        translate: "TRANSACTIONHISTORYUSER",
        url: "/user/transactionHistory",
      },
      {
        id: "transactionhistory4",
        title: "All Transaction History",
        type: "item",
        translate: "TRANSACTIONHISTORYALL",
        url: "/all/transactionHistory",
      },
      {
        id: "transactionhistory3",
        title: "Transaction History",
        type: "item",
        translate: "TRANSACTIONHISTORYADMIN",
        url: "/agent/adminHistory",
      },
    ],
  },
  {
    id: "pot(egg)1",
    title: "Pot Charging/Payment",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.potHistory,
    translate: "POT",
    access: ["user"],
    children: [
      {
        id: "transactionhistoryPot",
        title: "Transaction History",
        type: "item",
        translate: "TRANSACTIONHISTORYAGENT",
        url: "/agent/transactionHistory",
      },
      {
        id: "transactionhistoryPot2",
        title: "Transaction History",
        type: "item",
        translate: "TRANSACTIONHISTORYUSER",
        url: "/user/transactionHistory",
      },
      {
        id: "transactionhistory4",
        title: "All Transaction History",
        type: "item",
        translate: "TRANSACTIONHISTORYALL",
        url: "/all/transactionHistory",
      },
      // {
      //   id: "charginhistory",
      //   title: "Charging History",
      //   type: "item",
      //   translate: "CHARGINGHISTORY",
      //   url: "/pot-charging-despensing/chargingHistory",
      // },
      // {
      //   id: "paymenthistory",
      //   title: "Payment History",
      //   type: "item",
      //   translate: "PAYMENTHISTORY",
      //   url: "/pot-charging-despensing/paymentHistory",
      // },
      // {
      //   id: "deposithistory",
      //   title: "Payment History",
      //   type: "item",
      //   translate: "withdraw_history",
      //   url: "/pot-charging-despensing/withdrawHistory",
      // },
    ],
  },
  {
    id: "agentUser",
    title: "Agent",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.Agents,
    translate: "AGENT",
    access: ["user"],
    children: [
      // {
      //   id: "transactionhistory",
      //   title: "Transaction History",
      //   type: "item",
      //   translate: "TRANSACTIONHISTORYAGENT",
      //   url: "/agent/transactionHistory",
      // },
      {
        id: "agentlist",
        title: "Agent List",
        type: "item",
        translate: "AGENTLIST",
        url: "/agent/agentList",
      },
      {
        id: "agenttreelist",
        title: "Agent Tree List",
        type: "item",
        translate: "AGENTTREELIST",
        url: "/agent/agentTreeList",
      },
      // {
      //   id: "createanagent",
      //   title: "Create An Agent",
      //   type: "item",
      //   translate: "CREATEAGENT",
      //   url: "/agent/createAgent",
      // },
      // {
      //   id: "requestrpoint",
      //   title: "Request Pot",
      //   type: "item",
      //   translate: "REQUESTRPOINT",
      //   url: "/agent/requestRpoint",
      // },
      // {
      //   id: "rpointrequstedlist",
      //   title: "Pot Requested List",
      //   type: "collapse",
      //   translate: "RPOINTREQUESTEDLIST",
      //   access: ["user", "cs"],
      //   //url: "/agent/rpointRequestedList",
      //   children: [
      //     {
      //       id: "subagentrequestpoint",
      //       title: "Sub Agent Requested Pot",
      //       type: "item",
      //       translate: "RPOINTREQUESTEDLIST",
      //       url: "/agent/rpointRequestedList",
      //     },
      //     {
      //       id: "myrequestpoint",
      //       title: "My Requested Pot",
      //       type: "item",
      //       translate: "my_requested_point",
      //       url: "/agent/MyRpointHistory",
      //     },
      //   ],
      // },
    ],
  },
  {
    id: "agentAdmin",
    title: "Agent",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.Agents,
    translate: "AGENT",
    access: ["admin"],
    children: [
      // {
      //   id: "transactionhistory",
      //   title: "Transaction History",
      //   type: "item",
      //   translate: "TRANSACTIONHISTORYAGENT",
      //   url: "/agent/transactionHistory",
      // },
      {
        id: "agentlist",
        title: "Agent List",
        type: "item",
        translate: "AGENTLIST",
        url: "/agent/agentList",
      },
      {
        id: "agenttreelist",
        title: "Agent Tree List",
        type: "item",
        translate: "AGENTTREELIST",
        url: "/agent/agentTreeList",
      },
      // {
      //   id: "createanagent",
      //   title: "Create An Agent",
      //   type: "item",
      //   translate: "CREATEAGENT",
      //   url: "/agent/createAgent",
      // },
      // {
      //   id: "rpointrequstedlist",
      //   title: "Pot Requested List",
      //   type: "item",
      //   translate: "RPOINTREQUESTEDLIST",
      //   url: "/agent/rpointRequestedList",
      // },
    ],
  },
  {
    id: "agentCs",
    title: "Agent",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: "",
    translate: "AGENT",
    access: ["cs"],
    children: [
      {
        id: "agentlist",
        title: "Agent List",
        type: "item",
        translate: "AGENTLIST",
        url: "/agent/agentList",
      },
    ],
  },

  {
    id: "user",
    title: "User",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    icon: menusIcons?.Users,
    translate: "USER",
    access: ["admin", "user", "cs"],
    children: [
      // {
      //   id: "gamehistory",
      //   title: "Game History",
      //   type: "collapse",
      //   translate: "GAMEHISTORY",
      //   access: ["admin", "user", "cs"],
      //   //url: "/agent/rpointRequestedList",
      //   children: [
      //     {
      //       id: "totalAPIGameHistory",
      //       title: "Total API Game History",
      //       type: "item",
      //       translate: "Total_api_game_history",
      //       url: "/user/gameHistory",
      //     },
      //   ],
      // },
      // {
      //   id: "transactionhistory",
      //   title: "Transaction History",
      //   type: "item",
      //   translate: "TRANSACTIONHISTORY",
      //   url: "/user/transactionHistory",
      // },
      {
        id: "bethistory",
        title: "Bet History",
        type: "item",
        translate: "BETHISTORY",
        url: "/user/betHistory",
      },
      {
        id: "userlist",
        title: "User List",
        type: "item",
        translate: "USERLIST",
        url: "/user/userList",
      },
      // {
      //   id: "depositandwithdrawal",
      //   title: "Deposit and Withdrawal",
      //   type: "item",
      //   translate: "DEPOSITWITHDRAWAL",
      //   url: "/user/depositWithdrawal",
      // },
    ],
  },
  {
    id: "statisticsAdmin",
    title: "Statistics",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    access: ["admin", "cs"],
    icon: menusIcons?.Statistics,
    translate: "STATISTISC",
    children: [
      // {
      //   id: "agentrevenuestatistics",
      //   title: "Agent Revenue Statistics",
      //   type: "item",
      //   translate: "AGENTRSTATISTICS",
      //   url: "/statistics/agentRevenueStatistics",
      // },
      // {
      //   id: "dailySummary",
      //   title: "Daily Summary",
      //   type: "item",
      //   translate: "DAILYSUMMARY",
      //   url: "/statistics/DailySummary",
      // },
      {
        id: "allagentrevenuestatistics",
        title: "Bottom Agent Revenue Statistics",
        type: "item",
        translate: "ALLAGENTREVENUESTAT",
        url: "/statistics/all-agentRevenueStatistics",
      },
      {
        id: "subagentrevenuestatistics",
        title: "Bottom Agent Revenue Statistics",
        type: "item",
        translate: "SUBAGENTRSTATISTICS",
        url: "/statistics/sub-agentRevenueStatistics",
      },
      {
        id: "statisticsByGame",
        title: "Statistics By Game",
        type: "item",
        translate: "statisticsByGame",
        url: "/statistics/statisticsByGame",
      },
      {
        id: "statisticsProviderByGame",
        title: "Provider Wise Per Game Revenue",
        type: "item",
        translate: "statisticsProviderByGame",
        url: "/statistics/providerStatisticsByGame",
      },
      {
        id: "revenuestatisticsbycasino",
        title: "Revenue Statistics by Casino",
        type: "item",
        translate: "REVENUECASINO",
        url: "/statistics/revenueStatisticsCasino",
      },
      {
        id: "revenuestatisticsbycasinoallmonth",
        title: "Revenue Statistics by Casino",
        type: "item",
        translate: "REVENUECASINOALLMONTH",
        url: "/statistics/revenueStatisticsCasinoAllMonth",
      },
      // {
      //   id: "allAgentList",
      //   title: "All Agent List",
      //   type: "item",
      //   translate: "ALLAGENTLIST",
      //   url: "/statistics/allAgentList",
      // },
      // {
      //   id: "userrevenuestatistics",
      //   title: "Agent Recharge Statistics",
      //   type: "item",
      //   translate: "AGENTRECHARGESTAT",
      //   url: "/statistics/userRevenueStatistics",
      // },
    ],
  },
  {
    id: "statisticsUser",
    title: "Statistics",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    access: ["user"],
    icon: menusIcons?.Statistics,
    translate: "STATISTISC",
    children: [
      // {
      //   id: "agentrevenuestatistics",
      //   title: "Agent Revenue Statistics",
      //   type: "item",
      //   translate: "AGENTRSTATISTICS",
      //   url: "/statistics/agentRevenueStatistics",
      // },
      {
        id: "subagentrevenuestatistics",
        title: "Bottom Agent Revenue Statistics",
        type: "item",
        translate: "SUBAGENTRSTATISTICS",
        url: "/statistics/sub-agentRevenueStatistics",
      },
      {
        id: "allagentrevenuestatistics",
        title: "Bottom Agent Revenue Statistics",
        type: "item",
        translate: "ALLAGENTREVENUESTAT",
        url: "/statistics/all-agentRevenueStatistics",
      },
      // {
      //   id: "potdistributionstatistics",
      //   title: "Pot Distribution Statistics",
      //   type: "item",
      //   translate: "POTDESTRIBUTION",
      //   url: "/statistics/potDestribution",
      // },
      {
        id: "statisticsByGame",
        title: "Statistics By Game",
        type: "item",
        translate: "statisticsByGame",
        url: "/statistics/statisticsByGame",
      },
      {
        id: "revenuestatisticsbycasino",
        title: "Revenue Statistics by Casino",
        type: "item",
        translate: "REVENUECASINO",
        url: "/statistics/revenueStatisticsCasino",
      },
      {
        id: "revenuestatisticsbycasinoallmonth",
        title: "Revenue Statistics by Casino",
        type: "item",
        translate: "REVENUECASINOALLMONTH",
        url: "/statistics/revenueStatisticsCasinoAllMonth",
      },
    ],
  },
  {
    id: "developer",
    title: "Developer",
    //subtitle: "Unique dashboard designs",
    type: "collapse",
    access: ["admin", "user", "cs"],
    icon: menusIcons?.Developer,
    translate: "DEVELOPER",
    children: [
      {
        id: "nativeapi",
        title: "Native API",
        type: "item",
        translate: "NATIVEAPI",
        url: "/statistics/nativeAPI",
      },
      {
        id: "extendedapi",
        title: "Seamless API",
        type: "item",
        translate: "EXTENDEDAPI",
        url: "/statistics/ExtendedAPI",
      },
      {
        id: "apierror",
        title: "API Error Log",
        type: "item",
        translate: "APIERRORLOG",
        url: "/statistics/APIerror",
      },
      {
        id: "vendorgamelist",
        title: "Vendor List",
        type: "item",
        translate: "VENDORLIST",
        url: "/statistics/Vendorlist",
      },
      {
        id: "gamelist",
        title: "Game List",
        type: "item",
        translate: "GAMELIST",
        url: "/statistics/Gamelist",
      },
    ],
  },
  {
    id: "login history",
    title: "Login history",
    //subtitle: "Unique dashboard designs",
    type: "group",
    icon: menusIcons?.Login,
    translate: "Login_history",
    url: "/login-history",
    access: ["admin", "user", "cs"],
  },
];

export default navigationConfig;

// https://icon-sets.iconify.design/heroicons-outline/template/
