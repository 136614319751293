import ChargingHistoryConfigs from "./charging-history/chargingHistoryConfigs";
import PaymentHistoryConfigs from "./payment-history/paymentHistoryConfigs";
import DepositHistoryConfigs from "./deposit-history/depositHistoryConfigs";

const potChargingConfigs = [
  ChargingHistoryConfigs,
  PaymentHistoryConfigs,
  DepositHistoryConfigs,
];

export default potChargingConfigs;
