// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-labet {
  background-color: #eaecf4;
  color: #6e707e;
  border-radius: 5px 0 0 5px;
}
.item-center {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}
.text-buttons .plusbtn:hover,
.text-buttons .plusbtn:active,
.text-buttons .minusbtn:hover,
.text-buttons .minusbtn:active {
  background-color: #b9b9b942;
}
.text-buttons .minusbtn,
.text-buttons .plusbtn {
  border: 1px solid #ddd;
  border-left: 0;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/main/r-site/agent/admin-management/GGRReport/provider.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,kCAAkC;AACpC;AACA;;;;EAIE,2BAA2B;AAC7B;AACA;;EAEE,sBAAsB;EACtB,cAAc;EACd,6BAA6B;AAC/B","sourcesContent":[".input-labet {\n  background-color: #eaecf4;\n  color: #6e707e;\n  border-radius: 5px 0 0 5px;\n}\n.item-center {\n  display: flex;\n  align-items: center !important;\n  justify-content: center !important;\n}\n.text-buttons .plusbtn:hover,\n.text-buttons .plusbtn:active,\n.text-buttons .minusbtn:hover,\n.text-buttons .minusbtn:active {\n  background-color: #b9b9b942;\n}\n.text-buttons .minusbtn,\n.text-buttons .plusbtn {\n  border: 1px solid #ddd;\n  border-left: 0;\n  background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
