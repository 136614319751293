const locale = {
  DASHBOARD: "ダッシュボード",
  MYPAGE: "マイページ",
  PROVIDERMANAGEMENT: "ベッティング管理",
  SERVICEDESK: "サービスデスク",
  POT: "ポットのチャージ/支払い",
  GameType: "ゲームタイプ",
  AGENT: "エージェント",
  AGENTLIST: "エージェントリスト",
  AGENTTREELIST: "エージェントツリーリスト",
  CREATEAGENT: "エージェントの作成",
  REQUESTRPOINT: "ポットのリクエスト",
  RPOINTREQUESTEDLIST: "ポットのリクエスト済みリスト",
  USER: "ユーザー",
  BETHISTORY: "ベット履歴",
  TRANSACTIONHISTORY: "取引履歴",
  USERLIST: "ユーザーリスト",
  DEPOSITWITHDRAWAL: "入金と出金",
  STATISTISC: "統計",
  CHARGINGHISTORY: "チャージ履歴",
  PAYMENTHISTORY: "支払い履歴",
  AGENTRSTATISTICS: "エージェント収益統計",
  SUBAGENTRSTATISTICS: "下位エージェント収益統計",
  AGENTNAMEID: "エージェント名（エージェントID）",
  POTDESTRIBUTION: "ポット分布統計",
  REVENUECASINO: "期間別ユーザー収益統計",
  REVENUECASINOALLMONTH: "ユーザー収益統計",
  DEVELOPER: "開発者",
  USERREVENUESTAT: "期間別ユーザー収益統計",
  NATIVEAPI: "ネイティブAPI",
  EXTENDEDAPI: "拡張API",
  APIERRORLOG: "APIエラーログ",
  MTRPOINT: "私のポットリクエスト済みリスト",
  AGENTRECHARGESTAT: "エージェントリチャージ統計",
  Agent_listofAdmin: "トランザクション詳細リスト",
  approved: "承認済み",
  not_approved: "未承認",
  Agent_Information_Query_API_api_users:
    "エージェント情報クエリAPI /api/users",
  POST_api_users: "POST /api/users",
  Description: "説明",
  all: "すべて",
  Example: "例",
  type: "タイプ",
  Get_list_of_all_users: "すべてのユーザーのリストを取得します。",
  Createanewuser: "新しいユーザーを作成します。",
  Distribution: "分布",
  Operational: "運用中",
  Concurrent: "同時進行中",
  Sales: "売上",
  search: "検索",
  date_search: "日付検索",
  agent_name: "エージェントUID",
  Req_ID: "リクエストID",
  user_id: "ユーザーID",
  agent_id: "エージェントID",
  parent_id: "親ID",
  bet_type: "ベットタイプ",
  game_vendor: "ゲームベンダー",
  noOfpoint: "ポットの数",
  rows_per_page: "ページごとの行数",
  status: "ステータス",
  payment_amount: "支払い金額",
  balance_after_payment: "支払い後の残高",
  receving_user_name: "受信ユーザー名",
  point_amount: "ポイント量",
  number: "番号",
  agent_distribution_statistics: "エージェント分布統計",
  REVENUESTATICS: "収益統計",
  step: "ステップ",
  affiliate_agent: "提携エージェント",
  agent: "エージェント",
  holding_amount: "保有額",
  rate: "レート",
  tot_amount_paid: "支払い総額",
  tot_amount_charge: "チャージ総額",
  signup_time: "登録時刻",
  view_previous_month: "前月のステータスを表示",
  view_next_month: "当月のステータスを表示",
  balance_amount: "残高額",
  select_provider: "プロバイダーを選択",
  provider: "プロバイダー",
  Before_Pot: "ポット前",
  user_deposit: "ユーザー入金",
  user_withdrawal: "ユーザー出金",
  export: "エクスポート",
  affiliated_agent: "提携エージェント",
  id: "ID",
  nick_name: "ニックネーム",
  password: "パスワード",
  memo: "メモ",
  rpoint: "ポット",
  rpoint_for_provider: "プロバイダー用ポット",
  cancellation: "キャンセル",
  produce: "生産",
  API_GUIDE: "APIガイド",
  Error_ID: "エラーID",
  Error_Message: "エラーメッセージ",
  entity: "エンティティ",
  Operator_Player_User_ID: "オペレータープレーヤーユーザーID",
  Game_Title: "ゲームタイトル",
  Unique_Request_ID: "一意のリクエストID",
  Apply_Filter: "フィルターを適用",
  Clear_Filter: "フィルターをクリア",
  Enter_unique_request_ID: "一意のリクエストIDを入力してください",
  add: "追加",
  Please_enter_numbers:
    "数字と英字、特殊文字を入力してください。IDは含まれないようにし、8文字以上である必要があります。",
  sub_agent_rpoint_request_list: "サブエージェントリクエストポット",
  action: "アクション",
  my_requested_point: "私のリクエストポット",
  Consolidated_Bet_History_List: "統合されたベット履歴リスト",
  user_name: "ユーザー名",
  UUID: "UUID",
  vender: "ベンダー",
  balance: "残高",
  paymentIn: "支払い受付",
  paymentOut: "支払い出金",
  result: "結果",
  game_id: "ゲームID",
  bet_money: "ベット金額",
  payment_before: "支払い前",
  payment_after: "支払い後",
  result_money: "結果の金額",
  amount: "金額",
  balance_before_withdraw: "出金前の残高",
  balance_after_withdraw: "出金後の残高",
  balance_before_deposit: "入金前の残高",
  balance_after_deposit: "入金後の残高",
  request_pot_from_super_agent: "スーパーエージェントからポットをリクエスト",
  R_Points: "R-ポイント",
  r_Points: "R-ポイント",
  receiving_agent: "受信エージェント",
  consolidated_transaction_history_list:
    "統合された取引履歴リスト",
  Affiliated_Agent_ID: "提携エージェントID",
  Affiliated_Agent: "提携エージェント",
  this_month_bet: "今月のベット",
  this_month_wing: "今月の勝利",
  profit_and_loss_only: "利益または損失",
  profit_and_loss: "今月の利益または損失",
  current_holding: "現在の保有額",
  point_currenly_held: "現在のポイント保有額",
  sugn_up_date: "登録日",
  balance_before_payment: "支払い前の残高",
  user_depo_withdra_details: "ユーザー入金と出金詳細",
  period_start_date_time: "期間開始日時",
  Copyright: "著作権",
  period_end_date_time: "期間終了日時",
  setting_info: "設定と情報",
  name_depositor: "入金者の名前",
  amount_request: "リチャージリクエスト金額",
  request_time: "リクエスト時刻",
  today_recharge: "今日のリチャージ金額",
  monthly_recharge: "今月のリチャージ金額",
  consolidated_bet: "統合されたベット履歴リスト",
  full_history: "完全な履歴",
  my_history: "私の履歴",
  child_history: "子履歴",
  agent_payment_history_list: "エージェント支払い履歴リスト",
  revenue_status: "収益ステータス",
  select_agent: "エージェントを選択",
  date: "日付",
  winning_amount: "勝利金額",
  revenue: "収益",
  January: "1月",
  February: "2月",
  March: "3月",
  April: "4月",
  May: "5月",
  June: "6月",
  July: "7月",
  August: "8月",
  September: "9月",
  October: "10月",
  November: "11月",
  December: "12月",
  data: "日付",
  pot_total_recived: "ポットの総受取額",
  total_pot_distribution: "ポットの総分布額",
  agent_distribution_statistics_for_best: "エージェント分布統計",
  distribution_statistics: "分布統計",
  casino_vendor: "カジノベンダー",
  amount_od_profit_loss: "利益または損失の金額（合計）",
  no_of_bet: "ベット数",
  no_of_wins: "勝利数",
  current_holding_amountD: "現在の保有額",
  sub_agent_aurrent_total_holding_amount:
    "サブエージェントの現在の総保有額",
  current_total_holding_of_lower_users: "下位ユーザーの現在の総保有額",
  today_profit_and_loss_amount: "今日の利益または損失の金額",
  number_of_bets_today: "今日のベット数",
  number_of_users_betting_today: "今日のベットユーザー数",
  total_number_of_subAgnets: "サブエージェントの総数",
  number_of_distribution_subAgnets: "分布サブエージェント数",
  number_of_operational_subAgents: "運用中のサブエージェント数",
  number_of_concurrent_subAgents: "同時進行中のサブエージェント数",
  people: "人",
  current_holding_amount: "現在の保有額",
  sub_agent_total_holding_amount: "サブエージェントの総保有額",
  total_holding_of_lower_users: "下位ユーザーの総保有額",
  providers: "プロバイダー",
  create_bet_limit: "ベット制限を作成",
  Maximumbetlimit: "最大ベット制限",
  edit_bet_limit: "ベット制限を編集",
  category: "カテゴリ",
  min_bet: "最小ベット",
  max_bet: "最大ベット",
  enabled: "有効",
  edit: "編集",
  select_provider_Create: "プロバイダーを選択",
  select_game: "ゲームを選択",
  min_bet_limit: "最小ベット制限",
  max_bet_limit: "最大ベット制限",
  create: "作成",
  Setting_and_Information: "設定と情報",
  basic: "基本",
  changep_assword: "パスワードを変更",
  basic_information: "基本情報",
  info: "情報",
  classification: "分類",
  nickname: "ニックネーム",
  api_key: "APIキー",
  set_ip: "IPを設定",
  set_IP_allowed_for_API_calls: "API呼び出しに許可されるIPを設定",
  callback_URL: "コールバックURL",
  account_number: "アカウント番号",
  sub_agent_current_total_holding_amount:
    "サブエージェントの現在の総保有額",
  total_amount_paid: "支払い総額",
  total_amount_charged: "チャージ総額",
  number_of_sub_agents: "サブエージェント数",
  number_of_lower_users: "下位ユーザー数",
  sub_agent: "サブエージェント",
  casino_users: "カジノユーザー",
  change: "変更",
  setting_para_1:
    "APIキーは開発に必要な情報です。開発者に渡してください。",
  setting_para_2:
    "API呼び出し許可されたIPを登録しない場合、複数のAPIから呼び出しできます。IPv6とIPv4を追加することもできます。",
  setting_para_3:
    "一度登録すると変更できません。変更する場合は、Honor Linkから変更をリクエストする必要があります。",
  setting_para_4:
    "複数のIPはカンマ（,）で区切られ、スペースは許可されていません。",
  setting_para_5:
    "運用中にAPI呼び出しを許可するIPを登録することをお勧めします。",
  setting_para_6:
    "CloudFlareやIncapsulaなどの外部プロキシサービスを使用する場合、要件が欠落する可能性があります。",
  setting_para_7:
    "コールバックサーバーがシンガポールにない場合、高速スピンができない可能性があり、応答の遅延が発生する場合があります。",
  setting_para_8:
    "統合されたウォレットを使用しない場合、この値を空白のままにしてください。",
  change_password: "パスワードを変更",
  confirm_password: "パスワードを確認",
  current_password: "現在のパスワード",
  new_password: "新しいパスワード",
  confirm_new_password: "新しいパスワードを確認",
  save: "保存",
  cancel: "キャンセル",
  Total_api_game_history: "合計APIゲーム履歴",
  casino_id: "カジノユーザーID",
  before_money: "前の金額",
  after_money: "後の金額",
  game_name: "ゲーム名",
  win_money: "勝利金額",
  pending: "保留中",
  Loading: "読み込み中",
  payment: "支払い",
  withdraw: "出金",
  create_payment: "支払いを作成",
  create_withdraw: "出金を作成",
  deposit_history: "入金履歴",
  agent_deposit_history_list: "エージェント入金履歴リスト",
  currency: "通貨",
  requester: "要求者",
  round_id: "ラウンドID",
  Timestamp: "タイムスタンプ",
  transaction_id: "取引ID",
  change_status: "ステータスを変更",
  vendor: "ベンダー",
  language: "言語",
  Change_agent_password: "エージェントパスワードを変更",
  time_zone: "タイムゾーン",
  statisticsByGame: "ゲーム別統計",
  sub_agent_statistics: "サブエージェント統計",
  my_statistics: "私の統計",
  provider_holding: "プロバイダー保有額",
  KRW_Holding_Amount: "KRW 保有額",
  EUR_Holding_Amount: "EUR 保有額",
  USD_Holding_Amount: "USD 保有額",
  JPY_Holding_Amount: "JPY 保有額",
  sub: "サブ",
  remain: "残り",
  distributed_by_currency: "通貨別の分配",
  title: "タイトル",
  application_date: "申請日",
  view_data: "データを表示",
  sign_out: "サインアウト",
  success: "成功",
  pending: "保留中",
  r_site_payment: "Rサイトの支払い",
  casino_payment: "カジノの支払い",
  casino_withdraw: "カジノの出金",
  r_site_withdraw: "Rサイトの出金",
  win: "勝利",
  loss: "敗北",
  fail: "失敗",
  casino_user_deposit: "カジノユーザーの入金",
  sub_agent_deposit: "サブエージェントの入金",
  sub_agent_withdraw: "サブエージェントの出金",
  sub_agent_win: "サブエージェントの勝利",
  sub_agent_loss: "サブエージェントの敗北",
  sub_agent_fail: "サブエージェントの失敗",
  operator_user_deposit: "オペレーターユーザーの入金",
  operator_user_withdraw: "オペレーターユーザーの出金",
  operator_user_win: "オペレーターユーザーの勝利",
  operator_user_loss: "オペレーターユーザーの敗北",
  operator_user_fail: "オペレーターユーザーの失敗",
  game_id: "ゲームID",
  total_revenue: "合計収益",
  previous_month: "前月",
  current_month: "今月",
  total: "合計",
  agent_profit_loss: "エージェントの利益と損失",
  agent_current_month: "エージェントの当月収益",
  agent_previous_month: "エージェントの前月収益",
  agent_lifetime: "エージェントのライフタイム収益",
  sub_agent_profit_loss: "サブエージェントの利益と損失",
  sub_agent_current_month: "サブエージェントの当月収益",
  sub_agent_previous_month: "サブエージェントの前月収益",
  sub_agent_lifetime: "サブエージェントのライフタイム収益",
  user_profit_loss: "ユーザーの利益と損失",
  user_current_month: "ユーザーの当月収益",
  user_previous_month: "ユーザーの前月収益",
  user_lifetime: "ユーザーのライフタイム収益",
  agent_revenue_statistics: "エージェントの収益統計",
  sub_agent_revenue_statistics: "サブエージェントの収益統計",
  user_revenue_statistics: "ユーザーの収益統計",
  sub_agent_revenue: "サブエージェントの収益",
  sub_agent_win_loss: "サブエージェントの勝敗",
  user_revenue: "ユーザーの収益",
  agent_distribution_statistics_by_provider:
    "プロバイダー別エージェント分布統計",
  provider_revenue_statistics: "プロバイダーの収益統計",
  total_agents: "合計エージェント数",
  total_sub_agents: "合計サブエージェント数",
  total_users: "合計ユーザー数",
  total_active_agents: "合計アクティブエージェント数",
  total_active_sub_agents: "合計アクティブサブエージェント数",
  total_active_users: "合計アクティブユーザー数",
  total_agents_this_month: "今月の合計エージェント数",
  total_sub_agents_this_month: "今月の合計サブエージェント数",
  total_users_this_month: "今月の合計ユーザー数",
  total_agents_previous_month: "前月の合計エージェント数",
  total_sub_agents_previous_month: "前月の合計サブエージェント数",
  total_users_previous_month: "前月の合計ユーザー数",
  total_agents_lifetime: "ライフタイムの合計エージェント数",
  total_sub_agents_lifetime: "ライフタイムの合計サブエージェント数",
  total_users_lifetime: "ライフタイムの合計ユーザー数",
  withdraw_history: "出金履歴",
  agent_withdraw_history_list: "エージェントの出金履歴リスト",
  request_pot_from_super_agent: "スーパーエージェントからポットをリクエスト",
  my_requested_pot: "私のリクエストポット",
  total_sub_agents_requesting_pot: "ポットをリクエスト中のサブエージェント数",
  total_users_requesting_pot: "ポットをリクエスト中のユーザー数",
  agent_requesting_pot: "ポットをリクエスト中のエージェント数",
  agent_withdraw: "エージェントの出金",
  approved: "承認済み",
  not_approved: "未承認",
  agent_pot_request: "エージェントのポットリクエスト",
  amount_requested: "リクエストした金額",
  amount_received: "受け取った金額",
  amount_distributed: "分配済み金額",
  pot_request_status: "ポットリクエストのステータス",
  view_details: "詳細を表示",
  sub_agent_pot_request: "サブエージェントのポットリクエスト",
  user_pot_request: "ユーザーのポットリクエスト",
  user_list: "ユーザーリスト",
  user_id: "ユーザーID",
  account_id: "アカウントID",
  bank_account_number: "銀行口座番号",
  bank_name: "銀行名",
  account_name: "口座名",
  holder_name: "名義人名",
  email: "Eメール",
  phone_number: "電話番号",
  registration_date: "登録日",
  withdrawal_history: "出金履歴",
  agent_withdraw_history_list: "エージェントの出金履歴リスト",
  current_balance: "現在の残高",
  withdrawal_amount: "出金金額",
  withdrawal_status: "出金ステータス",
  deposit_history: "入金履歴",
  agent_deposit_history_list: "エージェント入金履歴リスト",
  deposit_amount: "入金金額",
  deposit_status: "入金ステータス",
  agent_deposit: "エージェントの入金",
  select_date_range: "日付範囲を選択",
  from: "から",
  to: "まで",
  search_results: "検索結果",
}
export default locale;
 
