import { lazy } from "react";

const PreDataControlApp = lazy(() => import("./preDataControl"));

const PreDataConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/agent/preDataControl",
      element: <PreDataControlApp />,
    },
  ],
};

export default PreDataConfig;
