// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.input-labet {
	background-color: #eaecf4;
	color: #6e707e;
	border-radius: 5px 0 0 5px;
}
.item-center {
	display: flex;
	align-items: center !important;
	justify-content: center !important;
}
.p-16 {
	padding: 16px !important;
}
.total-row td {
	/* background-color: #573ef0 !important; */
	color: #213772 !important;
	font-weight: 600 !important;
}

@media(max-width:400px){
	.mainboxs .inpbox{
		margin-right: 0;
		margin-bottom: 10px;
	}
	.mainboxs  .flex.item-center,
	.mainboxs .inpbox{
		width: 100%;
	}
}
.cardcontent1{
    /* max-height: 720px; */
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/main/r-site/statistics/all-agent-revenue-statistics/subagentRevenue.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;CACC,yBAAyB;CACzB,cAAc;CACd,0BAA0B;AAC3B;AACA;CACC,aAAa;CACb,8BAA8B;CAC9B,kCAAkC;AACnC;AACA;CACC,wBAAwB;AACzB;AACA;CACC,0CAA0C;CAC1C,yBAAyB;CACzB,2BAA2B;AAC5B;;AAEA;CACC;EACC,eAAe;EACf,mBAAmB;CACpB;CACA;;EAEC,WAAW;CACZ;AACD;AACA;IACI,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":["/** @format */\n\n.input-labet {\n\tbackground-color: #eaecf4;\n\tcolor: #6e707e;\n\tborder-radius: 5px 0 0 5px;\n}\n.item-center {\n\tdisplay: flex;\n\talign-items: center !important;\n\tjustify-content: center !important;\n}\n.p-16 {\n\tpadding: 16px !important;\n}\n.total-row td {\n\t/* background-color: #573ef0 !important; */\n\tcolor: #213772 !important;\n\tfont-weight: 600 !important;\n}\n\n@media(max-width:400px){\n\t.mainboxs .inpbox{\n\t\tmargin-right: 0;\n\t\tmargin-bottom: 10px;\n\t}\n\t.mainboxs  .flex.item-center,\n\t.mainboxs .inpbox{\n\t\twidth: 100%;\n\t}\n}\n.cardcontent1{\n    /* max-height: 720px; */\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
