/** @format */
import { Default } from "flatpickr/dist/l10n/default.js";
const locale = {
  DASHBOARD: "Dashboard",
  MYPAGE: "My Page",
  GGR: "Provider GGR",
  TOP: "Top",
  lower: "lower part",
  DETAILSHISTORY: "Details History",
  PROVIDERMANAGEMENT: "Betting Management",
  USERCALLMANAGEMENT: "User Call Management",
  SERVICEDESK: "Service Desk",
  POT: "Pot History",
  POT: "Pot",
  AGGREGATOR_PROVIDER: "Aggregator/Provider",
  GameType: "Game Type",
  GAMETYPE: "GAME TYPE",
  TYPE: "TYPE",
  GAMELIST: "Game List",
  TUTORIAL: "Tutorial",
  AGENT: "Agent",
  AllAGENTS: "All Agent",
  NOTSETAGENTS: "Not Set Agents",
  SEAMLESS: "seamless Agents",
  CANCELBETS: "Cancel Bets",
  TRANSFERAGENTS: "Transfer Agents",
  AGENTLIST: "Agent List",
  AGENTTREELIST: "Agent Tree List",
  CREATEAGENT: "Create Agent",
  Invest_Agent_GGR_Balance : "Invest Agent GGR Balance",
  TOTAL_GGR_BALANCE : "TOTAL GGR BALANCE",
  Honor_Link : "Honor Link",
  Common_Invest : "Common Invest",
  valid_id: "Please enter valid id",
  Invalid_nickname: "Invalid nickname",
  Invalid_input: "Invalid input",
  Invalid_Password: "Invalid Password",
  REQUESTRPOINT: "Request Pot",
  RPOINTREQUESTEDLIST: "Pot Requested List",
  USER: "User",
  BEHAVIORLOG: "Behavior Log",
  VENDORMANAGEMENT: "Vendor List Management",
  USERMONEYMANAGEMENT: "User Money Management",
  EditVendor: "Edit Vendor",
  Give_call_to: "Give Call",
  BETHISTORY: "Bet History",
  TRANSACTIONHISTORY: "Transaction History",
  TRANSACTIONHISTORYAGENT: "Agent Pot",
  TRANSACTIONHISTORYUSER: "User Pot",
  allPotHistory:"Pot Transaction",
  TRANSACTIONHISTORYALL:"Pot Transaction",
  FROM_USER_AGENT: "From (Agent/User)",
  TRANSACTIONHISTORYADMIN: "Admin Pot",
  USERLIST: "User List",
  DEPOSITWITHDRAWAL: "Deposit & Withdrawal",
  STATISTISC: "Statistics",
  CHARGINGHISTORY: "Charging History",
  PAYMENTHISTORY: "Payment History",
  AGENTRSTATISTICS: "Agent Revenue Statistics",
  ALLAGENTLIST: "All Agent List",
  SUBAGENTRSTATISTICS: "Bottom Agent Revenue",
  AGENTNAMEID: "Agent Name(Agent Id)’s",
  POTDESTRIBUTION: "Pot Distribution Statistics",
  REVENUECASINO: "Per User Revenue",
  REVENUECASINOALLMONTH: "Monthly user revenue",
  DEVELOPER: "Developer",
  VENDORLIST: "Vendor List",
  VENDORNAME: "Vendor Name",
  VendorNameKr: "Vendor Name Kr",
  VENDORID: "Vendor ID",
  GAMELIST: "Game List",
  LOBBYLIST: "Lobby List",
  USERBETTINGLIST: "User Betting List",
  GLOBALSETTING: "GLOBAL SETTING",
  SETTINGBYTYPE: "SETTING BY TYPE",
  VENDORSPECIFICSETTING: "VENDOR SPECIFIC SETTING",
  GAMESEPECIFSETTING: "GAME SEPECIF SETTING",
  USERBETTINGMANAGEMENT: "User Betting Management",
  ACTION: "Action",
  Excel: "Excel",

  USERREVENUESTAT: "User Revenue Statistics By Period",
  NATIVEAPI: "Transfer API",
  EXTENDEDAPI: "Seamless API",
  APIERRORLOG: "API Error Log",
  MTRPOINT: "My Pot Requested List",
  AGENTRECHARGESTAT: "Agent Recharge Statistics",

  Agent_listofAdmin: "List of Transaction Details",
  DisableGames: "Disabled Games",
  BettingLimits: "Betting Limit's",
  MaxWining: "Maximum winning",
  approved: "Approved",
  reject: "Reject",
  not_approved: "Not Approved",
  Agent_Information_Query_API_api_users:
    "Agent Information Query API /api/users",
  POST_api_users: "POST /api/users",
  Description: "Description",
  all: "All",
  Example: "Example",
  type: "Type",
  sub_type: "Sub_Type",
  allow_ip: "Allow Ip",
  Get_list_of_all_users: "Get a list of all users.",
  Createanewuser: "Create a new user.",
  Distribution: "Distribution",
  Operational: "Operational",
  Concurrent: "Concurrent",
  Sales: "Sales",
  search: "Search",
  date_search: "Search",
  agent_name: "Agent UID",
  Req_ID: "Req. ID",
  no: "No",
  user_id: "User ID",
  agent_id: "Agent ID",
  parent_id: "Parent ID",
  bet_type: "Bet Type",
  noOfpoint: "No: of Pot",
  rows_per_page: "",
  status: "Status",
  payment_amount: "Payment Amount",
  balance_after_payment: "Balance After Payment",
  receving_user_name: "Receiving User Name",
  point_amount: "	Point Amount",
  number: "Number",
  agent_distribution_statistics: "Agent Distribution Statistics",
  REVENUESTATICS: "Revenue Statics",

  Ooops: "Ooops...",
  PageNotfound: "The page you requested could not be found.",
  BackToDashboard: "Back to Dashboard",
  Something_went_wrong: "Something went wrong!",
  ServerError500: "Server Error 500. Our staff has been notified, thank you for your understanding.",
  under_maintenance: "We are under scheduled maintenance.",
  Sorry_inconvenience: "Sorry for the inconvenience, we will be back shortly!",

  step: "Step",
  affiliate_agent: "Affiliated Agent (Nickname)",
  affiliate_agent_balance: "Affiliated Agent Balance",
  affiliate_agent_ggr: "Invest Agent ID",
  affiliate_agent_balance_ggr: "Invest Agent Balance",
  agent: "Agent",
  holding_amount: "Holding Amount",
  rate: "Rate",
  tot_amount_paid: "Total Payout Amount",
  tot_amount_charge: "Total Charge Amount",
  signup_time: "Signup Time",
  view_previous_month: "View Previous Month's Status",
  view_next_month: "View Current Month's Status",
  balance_amount: "Balance Amount",
  select_provider: "Select Provider",
  provider: "Provider",
  Before_Pot: "Before Pot",
  user_deposit: "User Deposit",
  user_withdrawal: "User Withdrawal",
  export: "Export",
  affiliated_agent: "Affiliated Agent",
  lastStatus: "Latest Status",
  affiliated_agent: "AFFILIATED AGENT",
  OPERATIVE_OPERATOR: "OPERATIVE AGENT",
  Round_id: "Round / Transaction ID",

  Round_ID_copied_successfully: "Round ID copied successfully",
  Transaction_ID_copied_successfully: "Transaction ID copied successfully",
  id: "ID",
  nick_name: "Nick Name",
  agentNickname: "Agent ID (Nick Name)",
  TRANSACTION_OPERATOR: "TRANSACTION AGENT",
  password: "Password",
  memo: "Memo",
  rpoint: "Pot",
  rpoint_for_provider: "Deposit Pot point after creating an Agent.",
  cancellation: "Cancellation",
  produce: "Produce",
  API_GUIDE: "API GUIDE",
  Setup_Guide: "Setup Guide",
  Instructions_for_use: "Instructions for use",
  Error_ID: "Error ID",
  Error_Message: "Error Message",
  entity: "Entity",
  Operator_Player_User_ID: "Agent Player User ID",
  Game_Title: "Game Title",
  Unique_Request_ID: "Unique Request ID",
  Apply_Filter: "Apply Filter",
  Clear_Filter: "Clear Filter",
  Enter_unique_request_ID: "Enter unique request ID",
  add: "Add",
  Please_enter_numbers:
    "Please enter numbers + English + special characters. ID cannot be included and must be at least 8 characters long.",
  sub_agent_rpoint_request_list: "Sub Agent Request Pot",
  action: "Action",
  my_requested_point: "My Requests Pot",
  FruitKing: "FruitKing",

  Consolidated_Bet_History_List: "Consolidated Bet History List",
  user_name: "User Name",
  UUID: "UUID",
  vender: "Vendor",
  balance: "Balance",
  paymentIn: "Payment In",
  paymentOut: "Payment Out",
  result: "Result",
  game_id: "Game ID",
  bet_money: "Bet Money",
  payment_before: "Payment Before",
  payment_after: "Payment After",
  result_money: "Result Money",
  amount: "Amount",
  balance_before_withdraw: "Balance Before Withdraw",
  balance_after_withdraw: "Balance After Withdraw",
  balance_before_deposit: "Balance Before Deposit",
  balance_after_deposit: "Balance After Deposit",
  request_pot_from_super_agent: "Request Pot From Super Agent",
  receiving_agent: "Receiving Agentr",
  consolidated_transaction_history_list:
    "Consolidated Transaction History List",
  Affiliated_Agent_ID: "Affiliated Agent ID",
  Affiliated_Agent: "Affiliated Agent",
  this_month_bet: "This Month's Bet",
  this_month_wing: "This Month's Winnings",
  win_amount: "total winnings",
  bet_details: "total bet",
  // profit_and_loss_only: 'Profit or Loss',
  profit_and_loss_only: "Net Result",
  // profit_and_loss: 'Profit or Loss For This Month',
  profit_and_loss: "Monthly Net",
  monthly_net: "Net Result",
  current_holding: "Current Holdings",
  point_currenly_held: "Points Currenty Held",
  sugn_up_date: "Sign Up Date",
  balance_before_payment: "Balance Before Payment",
  user_depo_withdra_details: "User Deposit and Withdrawal Details",
  period_start_date_time: "Period Start Date & Time",
  Copyright: "Copyright",
  period_end_date_time: "Period End Date & Time",
  SelectDate: "Select Date",
  start_date: "Start Date",
  end_date: "End Date",
  hour: "Hour",
  day: "Day",
  days: "Days",
  week: "Week",
  month: "Month",
  UseR_count: "UseR(count)",
  Start_Pot_money: "Start Pot-money",
  End_pot_money: "End Pot-money",
  Pot_money_Overview: "Pot-money Overview",
  Participating_users: "Participating users",
  Administrator_OP_Payment: "Administrator-OP Payment",
  Administrator_OP_withdraw: "Administrator-OP withdraw",
  Bet: "Bet",
  Bet_fallure_refund: "Bet fallure/refund",
  Winning: "Winning",
  Sum: "Sum",
  Rewards: "Rewards",
  case: "case",
  number_of_people: "number of people",



  today: "Today",
  yesterday: "Yesterday",
  This_Month: "This Month",
  Past_Month: "Past Month",
  Past_3_months: "Past 3 months",
  any_date: "Any date",
  selected_date: "Selected date",
  setting_info: "Settings and Information",
  name_depositor: "Name Of Depositor",
  amount_request: "Amount Requested to Recharge",
  request_time: "Request Time",
  today_recharge: "TODAY'S RECHARGE AMOUNT",
  monthly_recharge: "MONTHLY RECHARGE AMOUNT",
  consolidated_bet: "Consolidated Bet History List",
  full_history: "Full History",
  my_history: "My History",
  child_history: "Child History",
  agent_payment_history_list: "Agent Payment History List",

  revenue_status: "REVENUE STATUS",
  select_agent: "Select Agent",
  date: "Date",
  winning_amount: "Winning Amount",
  revenue: "Revenue",
  left: "Left",
  GGRUSEGELIMIT: "GGR usege limit",
  GGRusegelimitLeft: "GGR usege limit [% Left]",

  // Months Name
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",

  data: "Date",
  pot_total_recived: "Pot Total Received",
  total_pot_distribution: "Total Pots Distribution",
  agent_distribution_statistics_for_best: "Agent Distribution Statistics for",
  distribution_statistics: "Distribution Statistics",
  casino_vendor: "Casino Vendor",
  // amount_od_profit_loss: 'Amount of Profit and Loss (Total)',
  amount_od_profit_loss: "Total Earnings Overview",
  no_of_bet: "Number of Bets",
  no_of_wins: "Number of Wins",

  current_holding_amountD: "CURRENT HOLDING AMOUNT",
  Over_balance_message: "Entered amount exceeds than current holding amount.",
  sub_agent_aurrent_total_holding_amount:
    "SUB AGENTS CURRENT TOTAL HOLDING AMOUNT",
  current_total_holding_of_lower_users: "Current Total Holding of Lower Users",
  today_profit_and_loss_amount: "TODAY PROFIT AND LOSS AMOUNT",
  number_of_bets_today: "NUMBER OF BETS TODAY",
  number_of_users_betting_today: "NUMBER OF USERS BETTING TODAY",
  total_number_of_subAgnets: "TOTAL NUMBER OF SUB AGENTS",
  number_of_distribution_subAgnets: "NUMBER OF DISTRIBUTION SUB AGENTS",
  number_of_operational_subAgents: "NUMBER OF OPERATIONAL SUB AGENTS",
  number_of_concurrent_subAgents: "NUMBER OF CONCURRENT SUB AGENTS",
  number_of_allagent_subAgents: "NUMBER OF ALL AGENTS",
  number_of_transfer_subAgents: "NUMBER OF TRANSFER AGENTS",
  number_of_seamless_subAgents: "NUMBER OF SEAMLESS AGENTS",
  number_of_notset_subAgents: "NUMBER OF NOT SET AGENTS",
  number_of_cancelbets_subAgents: "NUMBER OF CANCEL BETS",
  number_of_new_games: "NUMBER OF NEW GAMES",
  number_of_current_games: "NUMBER OF CURRENT GAMES",
  number_of_deleted_games: "NUMBER OF DELETED GAMES",
  people: "Agent",
  current_holding_amount: "Current Holding Amount",
  sub_agent_total_holding_amount: "Sub Agent Total Holding Amount",
  total_holding_of_lower_users: "Total Holdings of Lower Users",
  total_balance: "Total Balance",
  estimated_balance: "Estimated Balance",

  providers: "Providers",
  create_bet_limit: "Create Bet Limit",
  Maximumbetlimit: "Maximum bet limit",
  edit_bet_limit: "Edit Bet Limit",
  category: "Category",
  min_bet: "Min Bet",
  max_bet: "Max Bet",
  enabled: "Enabled",
  edit: "Edit",

  select_provider_Create: "Select Provider",
  select_game: "Select Game",
  min_bet_limit: "Min Bet Limit",
  max_bet_limit: "Max Bet Limit",
  create: "Create",

  Setting_and_Information: "Settings and Information",
  basic: "Basic",
  changep_assword: "Change Password",
  basic_information: "BASIC INFORMATION",
  info: "Info",
  classification: "Classification",
  nickname: "Nickname",
  last_play_provider_id: "Last Play ProviderId",
  last_play_vendor: "Last Play Vendor",
  last_play_game: "Last Play Game",
  last_play_lobbie: "Last Play Lobby",
  invest_token: "Invest Token",
  common_invest_token: "Common Invest Token",
  api_key: "API Key",
  set_ip: " Set IP",
  set_IP_allowed_for_API_calls: "Set IP Allowed for API Calls",
  callback_URL: "Callback URL",
  account_number: "Account Number",
  sub_agent_current_total_holding_amount:
    "Sub-Agent Current Total Holding Amount",
  total_amount_paid: "Total Amount Paid",
  total_amount_charged: "Total Amount Charged",
  number_of_sub_agents: "Number of Sub Agents",
  number_of_lower_users: "Number of Lower Users",
  sub_agent: "Sub Agent",
  casino_users: "Casino Users",
  change: "Change",
  setting_para_1:
    "API KEY is information required by development.Please pass it on the developer",
  setting_para_2:
    "If you do not register the IP not adding IP restrict. you can make calls from multiple APIs. Also you can add IPv6 and IPv4(Can add multiple IPs with adding (,) comma)",
  setting_para_3:
    "Onces registered, it cannot be modified. If you want o change it, you must request a change from Honor Link.",
  setting_para_4:
    "Multiple, ips are separated by commas () and spaces are not allowed.",
  setting_para_5:
    "We recommended that you register an IP that allows API calls during operation.",
  setting_para_6:
    "If you use an external proxy service such as CloudFlare or Incapsula, it will cause missing requirements.",
  setting_para_dis:
    "After registering callback URLs, it will be called as /changeBalance and /balance to your operator site. It should be a POST method API and it should be ended with changeBalance and balance endpoint names. also, accept only HTTPS",
  setting_para_7:
    "If the callback server is not located in Singapore, fast spin may not be possible and response delay may occur.",
  setting_para_8:
    "If you do not use an integrated wallet, be sure to leave this value blank.",
  excel: "Excel",
  change_password: "Change Password",
  confirm_password: "Confirm Password",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  save: "Save",
  cancel: "CANCEL",
  cancel_n: "cancel",
  delete: "Delete",

  Total_api_game_history: "Total API Game History",
  casino_id: "Casino User ID",
  before_money: "Before Money",
  moneySync: "Money Sync",
  after_money: "After Money",
  game_name: "Game Name",
  win_money: "Winning Money",
  pending: "Pending",
  Click_me: "Click me",

  Loading: "Loading",
  payment: "Payment",
  get_refund: "Refund",
  withdraw: "Withdraw",
  clear: "Clear",
  create_payment: "Create Payment",
  create_withdraw: "Create Withdraw",

  deposit_history: "Deposit History",
  withdraw_history: "Withdraw History",
  agent_deposit_history_list: "Agent Deposit History List",
  currency: "Currency",
  userBalance: "Total User Balance",
  agentBalance: "Agent Balance",
  KRW: "KRW",
  USD: "USD",
  JPY: "JPY",
  EUR: "EUR",
  emptyField: "Please fill out the input fields first",
  emptyIP: "Please enter your IP first",
  requester: "Requester",
  round_id: "Round ID",
  Timestamp: "Timestamp",
  transaction_id: "Transaction ID",
  change_status: "Change Status",
  status_desc:
    "When active status API key will be activated. When disable status API key will be deactivated. You can block casino user API by set status to deactive status.",
  vendor: "Vendor",
  language: "Language",
  Korean: "Korean",
  KRW: "KRW",
  USD: "USD",
  JPY: "JPY",
  EUR: "EUR",
  BET: "BETS",
  ASTRO: "Astro History",
  ObjectData: "OBJECT DATA",
  BRANDINGMANAGEMENT: "Branding Management",
  UPLOADSUCCESS: "Uploaded Successfully",
  BRANDINGDETAILS: "Upload Branding Details",
  UPLOADEDBRANDINGDETAILS: "Uploaded Branding Details",
  LOGOIMG: "Upload Logo Image",
  FAVIIMG: "Upload Favicon Image",
  SIGNUPBACKGROUNDIMG: "Upload Signup Background Image",
  LOGOIMGUPLOADED: "Logo Image",
  FAVIIMGUPLOADED: "Favicon Image",
  SIGNUPBACKGROUNDIMGUPLOADED: "Signup Background Image",
  Footer: "Footer",
  Branding_Unique_Name: "Branding Unique Name",
  Change_agent_password: "Change agent password",
  time_zone: "Time Zone",
  change_time_zone: "Change Time Zone",
  statisticsByGame: "Per Game Revenue",
  statisticsProviderByGame: "Provider Per Game Revenue",
  sub_agent_statistics: "Sub Agent Statistics",
  my_statistics: "My Statistics",
  //  new dash board
  provider_holding: "Provider Holding",
  KRW_Holding_Amount: "KRW Holding Amount",
  EUR_Holding_Amount: "EUR Holding Amount",
  USD_Holding_Amount: "USD Holding Amount",
  JPY_Holding_Amount: "JPY Holding Amount",

  sub: "SUB",
  remain: "REMAIN",
  distributed_by_currency: "Distributed by Currency",

  //betting management
  title: "Title",
  Betting_amount_management:
    "Please Provide details at the top of the maximum Betting amount management page as an example of how to use it",
  Winning_amount_management:
    "Please Provide details at the top of the maximum winning amount management page as an example of how to use it",
  Game_amount_management:
    "Please Provide details at the top of the maximum game amount management page as an example of how to use it",
  //Deposit History
  application_date: "Date",

  //betHistory
  view_data: "View Data",

  //nav bar
  sign_out: "Sign Out",
  //chargin history
  success: "Success",
  pending: "Pending",
  //payment history
  r_site_payment: "R Site Payment",
  casino_payment: "Casino Payment",
  //deposite history
  casino_withdraw: "Casino Withdraw",
  r_site_withdraw: "R Site Withdraw",
  //user bet history
  win: "WIN",
  loss: "LOSS",
  callbackerror: "CALLBACK ERROR",
  fail: "Fail",
  bonus: "BONUS",

  casino_user_deposit: "Casino User Deposit",
  sub_agent_deposit: "Sub Agent Deposit",
  casino_user_wthdrawal: "Casino User Withdrawal",
  sub_agent_wthdrawal: "Sub Agent Wthdrawal",
  //agent create agent
  Cant_given_USD_for_Agent: "Can't given USD for Agent.",
  Cant_given_EUR_for_Agent: "Can't given EUR for Agent.",
  Cant_given_JPY_for_Agent: "Can't given JPY for Agent.",
  Cant_given_KRW_for_Agent: "Can't given KRW for Agent.",
  Amount_Can_given_for_Agent: "Amount Can given for Agent.",
  Agent_Distribution_Money: "Agent Distribution Money",
  Cant_given_for_Agent: "Cant given for Agent",

  //betting management
  how_to_set_maximum_bet_amount_limit: "How to set a maximum bet amount limit",
  the_maximum_bet_limit_is_applied_following_order:
    "The maximum bet limit is applied in the following order.",

  by_type: " By Type",
  by_vendor: "By Vendor",
  by_game: " By Game",
  //my page
  copy: "Copy",
  copied : "Copied",
  //betting management page
  classification_of_settings: "Classification of settings",
  save_settings: "Save Settings",
  vendor_specific_settings: "Vendor-specific settings",
  settings_by_type: "Settings by type",
  game_specific_settings: "Game-specific settings",
  individual_settings_take_over_global_settings:
    "Individual settings take precedence over global settings",
  for_all_games: "for all games",
  global_settings: "Global Settings",
  maximum_bet_amount: "Maximum bet amount (Unit:pot)",
  set_target: "Set target",
  maximum_amount: "Maximum Amount",
  nodata: "No data",

  game_vendor: "Game Vendor",
  vendor_selection: "Vendor Selection",

  Cancel: "Cancel",
  Refund: "Refund",
  Callback_Error: "Callback Error",

  //alerts
  error_alert: "Internal Error",
  please_change_enable_yes: "Please Change Enable to Yes",
  bet_limit_created: "Bet Limit Created",
  already_registered_on_list: "Already registered on the list.",
  please_enter_valid_data: "Pleased enter valid data",
  something_went_wrong_please_try_again:
    "Something went wrong! Pleased try again",
  max_bet_deleted_successfuly: "Max Bat Deleted Successfully!",
  bet_limit_updated: "Bet Limit Updated!",
  widthdraw_success: "Withdraw Success",
  payment_success: "Payment Success",
  amount_must_be_more_than: "Amount must be more than 0",
  status_has_changed: "Status Change Success",
  user_details_update_successfull: "User Details Updated successfully",
  user_cration_failed: "User Creation Failed!",
  user_created_successfuly: "User Created successfully!",
  password_should_contain_at_least_one:
    "Password should contain at least one character, one number, one special character and minimum length 8.",
  rate_is_required: "Rate is Required",
  id_should_contain_leters_and_numbers:
    "ID should contain at only letters and numbers.",
  pot_is_required: "Pot is Required",
  language_is_required: "language is Required",
  currency_is_required: "currency is Required",
  password_is_required: "Password is Required",
  nick_name_is_required: "Nickname is Required",
  pot_request_accepted: "Pot Request Accepted",
  id_is_required: "ID is Required",
  pot_request_rejected: "Pot Request Rejected",
  r_point_requested_successfully: "R-Points Requested Successfully",
  new_password_comfirm_pass_not_matched:
    "New password & confirm Password not matched!",
  password_changed_successfully: "Password changed successfully",
  error_occurred_please_try_again: "An error occurred. Please try again later.",
  account_number_is_wrong: "Account Number is wrong",
  account_number_changed: "Account Number Changed",
  account_number_required: "Account Number is Required",
  url_is_wrong: "URL is wrong",
  callback_URL_changed: "Callback URL Changed",
  ip_changed: "IP Changed",
  ip_required: "IP is Required",
  api_key_changed: "API key Changed",
  //notifications
  mark_all_as_read: "Mark All As Read",
  noNotification: "No Notification",
  has_requested: "has requested",
  r_points: "r-points",
  R_Points: "Pot-Points",
  api_coppied: "API Coppied",
  change_balance_callback_URL: "Change Balance Callback URL",
  balance_callback_URL: "Balance Callback URL",

  //error messages
  invalid_value: "Enter valid data",
  account_no_changed_successfully: "Account NO. changed successfully",
  admin_only_can_approve: "Admin only can Approve",
  agent_balance_deducted: "Agent Balance Deducted",
  agent_balance_fetched: "Agent Balance Fetched",
  agent_holding_balance_fetched: "Agent Holding Balance Fetched",
  agent_holding_summary_get: "Agent Holding Summary Get",
  agent_name_list_fetch_failed: "Agent Name list fetch Failed",
  agent_not_available: "Agent Not Available",
  agent_not_found: "Agent Not Found",
  all_payment_list_fetch_failed: "All Payment list fetch failed",
  allow_ips_changed_successfully: "Allow IPs changed successfully",
  amount_is_required: "Amount is required",
  api_key_invalid: "Api Key invalid",
  api_key_remove_failed: "API KEY remove failed",
  api_key_remove_successfully: "API KEY remove successfully",
  api_key_required: "API Key Required",
  apikey_is_required: "ApiKey is required",
  authentication_is_missing: "Authentication is missing",

  balance_api_path_is_wrong: "balance API path is wrong",
  bet_creation_failed: "Bet Creation Failed",
  bet_data_fetch_failed: "Bet data fetch failed",
  bet_getting_unsuccessfully: "Bet Getting Unsuccessfully",
  bet_round_not_same: "Bet round not same",
  bet_transaction_id_is_required: "Bet transaction id is required",
  callback_url_changed_successfully: "Callback URL changed successfully",
  cant_get_activate_provider: "Can't get activate provider",
  casino_user_balance_updated: "Casino User Balance Updated",
  casino_user_not_found: "Casino User Not Found",
  casino_userlist_fetched_successfully: "Casino user list fetched successfully",
  casinousername_is_required: "Casino username is required",
  changebalance_api_path_is_wrong: "Change balance API path is wrong",
  charge_details_fetch_failed: "Charge details fetch failed",
  charge_list_fetch_failed: "Charge List fetch failed",
  check_inputed_r_points: "Check inputed R Points",
  currency_details: "Currency details",
  currency_not_found: "currency not found",
  data_fetch_success: "Data fetch success",
  date_filter_payment_list_fail: "Date Filter Payment list Fail",
  date_filter_payment_list_fetched_successfully2:
    "Date Filter Payment list fetched successfully",
  end_date_is_required: "End date is required",
  end_date_should_be_greater_than_or_equal_to_start_date:
    "End date should be greater than or equal to start date",
  Enter_valid_IP: "Enter valid IP",
  filtered_user_details_fetch_failed: "Filtered User Details fetch Failed",
  filtered_user_details_fetched_successfully:
    "Filtered User Details fetched successfully",
  game_history_data_get_failed: "Game History Data Get Failed",
  game_history_data_get_successfully: "Game History Data Get successfully",
  get_casino_users_successfully: "Get Casino users successfully",
  holdings_data_fetched_successfully: "Holdings Data Fetched Successfully",
  invalid_per_page_value_it_should_be_between_1_and_1000:
    "Invalid per_page value. It should be between 1 and 1000.",
  invalid_verification_code: "Invalid Verification Code",
  language_details: "Language Details",

  language_is_requried: "Language is requried",
  language_not_found: "Language not found",
  month_agent_recharge_revenue_data_fetch_failed:
    "Month Agent Recharge Revenue data fetch failed",
  month_agent_recharge_revenue_data_fetched_successfully:
    "Month Agent Recharge Revenue data fetched successfully",
  month_casino_user_data_fetched_successfully:
    "Month Casino User data fetched successfully",
  month_casino_user_data_not_found: "Month Casino User data not found",
  month_revenue_agent_data_fetch_failed:
    "Month Revenue Agent data fetch failed",
  month_revenue_agent_data_fetched_successfully:
    "Month Revenue Agent data fetched successfully",
  my_point_request_data_fetched_successfully:
    "My point Request Data fetched successfully",
  need_to_refill_rpoints: "Need to refill r-points",
  nickname_filter_payment_list_fetch_failed:
    "Nickname Filter Payment list fetch Failed",
  no_user_found: "No user found",
  only_can_accept_https_protocol: "Only can accept HTTPS protocol",
  parent_filter_payment_list_fetch_failed:
    "Parent Filter Payment list fetch Failed",
  parentfilter_bet_data_fetch_failed: "ParentFilter Bet data fetch failed",
  password_not_matched: "Password not matched",
  payment_list_fetch_failed: "Payment list fetch Failed",
  payment_list_fetched_successfully: "Payment list fetched successfully",
  payment_request_found: "Payment Request Found",
  payment_request_not_found: "Payment Request Not Found",
  point_request_details_fetched_successfully:
    "Point Request details fetched Successfully",
  pot_value_cant_be_less_than_0: "Pot value can't be less than 0",
  providers_not_activated: "Providers not activated",
  r_points_amount_cant_be_less_than_0: "R points amount can't be less than 0",
  r_points_limit_exceeded: "R points limit exceeded",
  rpoint_balance_less_than_request: "R-Point Balance Less than Request",
  rpoint_request_approved: "R-Point Request Approved",
  rpoint_request_rejected: "R-Point Request Rejected",
  rate_value_cant_be_accept: "Rate value can't be accept",
  rate_value_cant_be_less_than_65: "Rate value can't be less than 6.5",
  request_rpoint_failed: "Request R-Point Failed",
  request_rpoint_successfully: "Request R-Point successfully",
  revenue_creation_failed: "Revenue Creation Failed",
  revenue_data_fetch_failed: "Revenue data fetch failed",
  revenue_data_fetched_successfully: "Revenue data fetched successfully",
  search_history_details_fetched_successfully:
    "Search History details fetched Successfully",
  something_went_wrong: "Something went wrong",
  start_date_is_required: "Start date is required",
  sub_agent_charge_list_fetch_failed: "Sub Agent Charge List fetch failed",
  sub_agent_list_fetch_failed: "Sub Agent List fetch failed",
  time_zone_is_requried: "Time Zone is requried",
  type_data_fetch_failed: "Type data fetch failed",
  type_data_fetched_successfully: "Type data fetched successfully",
  update_faild: "Update faild",
  update_success: "Update Success",
  game_added: "Game Added",
  user_api_not_found: "User API not found",
  user_created_failed: "User Created failed",
  user_details_fetched_successfully: "User details fetched Successfully",
  user_details_updated_successfully: "User Details Updated successfully",
  user_found: "User Found",
  user_holding_fetching_failed: "User holding fetching failed",
  user_holding: "User holding",
  user_id_is_required: "User ID is required",
  user_is_required: "user is required",
  user_list_fetch_failed: "User list fetch Failed",
  user_list_fetched_successfully: "User List fetched successfully",
  user_list_with_count_result_fetch_failed:
    "User List With Count Result fetch Failed",
  user_list_with_count_result_fetched_successfully:
    "User List With Count Result fetched successfully",
  user_not_found: "User not found",
  user_registration_successfull: "User Registration Successfull",
  uuid_is_required: "UUID is required",
  vendor_is_required: "Vendor is required",
  verification_code_confirmed: "Verification Code Confirmed",
  verification_code_expired: "Verification Code expired",
  withdraw_request_found: "Withdraw Request Found",
  withdraw_request_not_found: "Withdraw Request Not Found",
  you_cant_update_your_own_details: "You can't update your own details!",
  money_less_than_request: "Money is less than request amount",
  no_data_available_in_table: "No Data Available in The Table",
  account_has_deactivated: "This account has deactivated",
  rate_value_must_be_more_than_than_your_rate:
    "Rate value must be more than than your rate",

  activation: "activation",
  activated_agent: "Activated Agent",
  deactivated_agent: "Deactivated Agent",
  total: "Total",
  Total_Deposited_Pot: "Total Deposited Pot",
  Today_Deposited_Pot: "Today Deposited Pot",
  Today_Withdraw_Pot: "Today Withdraw Pot",
  Today_Refund_Pot: "Today Refund Pot",
  Remain_Pot: "Remain Pot",
  today: "Today",
  total_bet: "Total Bet Amount",
  call_money: "Call Money",
  apply_money: "Apply Money",
  total_win: "Total Win Amount",
  total_call: "Total Call",
  current_rtp: "Current RTP",
  target_rtp: "Target RTP",
  total_call: "Total Call",
  user_call_history: "User Call History",

  agent_deposit: "Agent Deposit",
  agent_withdrawal: "Agent Widthrawal",

  deposit: "Deposit",

  ADMINMANAGEMENT: "Admin Management",
  GGRLIMIT: "GGR Limit",
  OPERATORIDS: "Agent ID",
  GENERATEPOT: "Generate POT",
  PREDATACONTROL: "Pre Data Management",
  PREDATACTRL: "PreData Control",

  limit: "Limit",
  current_usage: "Current Usage",
  percentage_usage: "Percentage Usage",
  limit_exceeded: "Limit Exceeded",
  operater_id: "Agent Id",
  deposit_to: "deposit to",
  history_details: "History Details",
  account: "'s account",
  withdrawan_from: "withdrawan from",
  asynchronously: "Synchronization Deposit",
  asynchronously_withdraw: "Synchronization Withdraw",

  synchronization_withdraw: "SYNCHRONIZATION WITHDRAW",
  synchronization_deposit: "Synchronization Deposit",

  select_wallet: "Select wallet type",
  wallet_type: "Wallet Type",
  select_wallet_wrong: "Wallet select selection failed",
  GAMEMANAGEMENT: "Game Management",
  how_to_set_game_disable: "How to set a Game disable",
  the_game_enable_disable_applied_following_order:
    "The game enable/disable is applied in the following order",
  request_data: "Request Data",
  user_payment_function_on_user_list: "User Payment function on User list",
  game_is_required: "Game is required!",
  game_disabled: "Game Disabled",
  WINMANAGEMENT: "Maximum Winning",
  win_limit_updated: "Win Limit Updated!",
  how_to_set_maximum_win_amount_limit: "How to set a maximum win amount limit",
  the_maximum_win_limit_is_applied_following_order:
    "The maximum win limit is applied in the following order.",
  Maximumwinlimit: "Maximum win limit",
  maximum_win_amount: "Maximum win amount (Unit:pot)",

  seamless_wallet: "Seamless Wallet",
  transfer_wallet: "Transfer Wallet",

  //error messages
  selected_date_should_not_more_one_days:
    "Selected date should not more than 1 days from today!",
  selected_date_should_not_more_three_days:
    "Selected date should not more than 3 days from today!",
  balance_before: "Balance Before",
  balance_after: "Balance After",

  upperDeposit:" Upper deposit",
  upperWithdraw:" Upper withdraw",
  paymentToSubAgent:"Payment to Sub Agent",
  fromSubAgent:"Withdrawal from Sub Agent",
  userDeposit:"Withdraw from user",
  userWithdraw:"Deposit to user",
  targetAgentUser:"Target Agent/User",

  please_enter_valid_password: "Please Enter valid password",
  for_game_type: "for game type",
  Selected_game_type: "Selected game type",
  for_vendor: "for vendor",
  Selected_vendor: "Selected vendor",
  for_game: "for game!",
  Selected_game: "Selected game",

  bet_limit_deleted: "bet limit deleted",
  bet_limit_deleted: "bet limit deleted",

  Login_history: "Login History",
  casino_entity: "casino entity",
  available_balance: "available Balance",
  available: "Availability",
  VENDORENABLE: "VENDOR ENABLE",
  Korea: "Korea",
  Other: "Other",
  enter_code: "Enter code",
  submit: "Submit",

  addNew: "Add New",
  addNewGame: "Add New Game",
  provideName: "Provider Name",
  realId: "Real Id",
  providerGameId: "Provider Game Id",
  realVendor: "Real Vendor",
  vendor: "Vendor",
  gameType: "Game Type",
  platform: "Platform",
  thumbnails: "Thumbnails",

  login_browser: "LOGIN BROWSER",
  login_ip: "LOGIN IP",
  login_browser_version: "LOGIN BROWSER VERSION",
  login_system: "LOGIN SYSTEM",
  login_date: "LOGIN DATE",
  logout_date: "LOGOUT DATE",
  login_status: "LOGIN STATUS",
  reg_date: "REG DATE",
  game_result: "Game Result",
  enable: "Enable",
  from: "From",
  to: "To",
  BALANCELIMIT: "Balance Limit",
  balance_limit_for_entity: "Balance Limit for entity",
  comment: "comment",
  total_cancel_amount: "Total Cancel Amount",
  total_refund: "Total Refund Amount",
  APIERRORLOG: "API Error Log",
  reset: "cancel",
  PROVIDER_APIERRORLOG: "Provider API Error Log",

  //errors
  changebalance_api_path_is_wrong: "changeBalance API path is wrong",
  balance_api_path_is_wrong: "balance API path is wrong",
  only_can_accept_https_protocol: "API path is wrong - Https",
  api_path_is_wrong: "API path is wrong - Https",
  enter_valid_ip: "Enter valid IP",

  username_is_required: "username is required ",
  current_secret_key_is_required: "current secret key is required",
  new_secret_key_is_required: "new secret key  is required",
  no_record_found: "No record found",
  secret_key_added: "Secret key added!",
  secret_key_updated: "Secret key updated ",
  please_enter_ip: "Please enter ip",
  //error end
  secret_key: "Secret Key",
  new_secret_key: "new secret key",
  add_new: "add new",
  update_key: "update key",
  current_secret_key: "current secret key",

  manage_login_secret: "Manage Login Secret",
  username: "username",
  select_an_agent: "Select An Agent",
  login_person: "login person",

  error_message: "Error message",
  game_title: "Game title (vendor)",
  Operator_player_user_ID: "Operator player user ID (username)",
  round_ID: "Round ID",
  timestamp: "Timestamp (Asia/Seoul)",
  user_payment_function_enabled: "User payment function enabled",
  user_payment_function_disable: "User payment function disabled",
  check_inputed_r_points: "Check inputed R Points",

  enter_unique_request_Id: "Enter unique request Id...",

  no_record_found: "No record found",
  current_key_not_matched: "Current key not matched!",
  username_already_exists: "username is required",
  disabled: "Disabled!",

  SYSTEMMANAGEMENT: "System Management",
  INVESTACCOUNT: "Invest Account",
  
  investDetails:"Invest Direct Details",
  GET:"GET",
  POST:"POST",
  PUT:"PUT",
  PATCH:"PATCH",
  DELETE:"DELETE",
  SEND:"SEND",
  Response:"Response",
  Error:"Error",


  BACK_OFC: "Backoffice pw",
  BACK_OFC_USER: "Backoffice User",
  Opcode: "Opcode",

  callback_url_customization: "Callback URL Customization",
  Add_Edit_Account: "Add/Edit Account",
  update: "Update",
  balance_limit_transactions: "Balance limit transactions",

  target: "User",
  Solution_Management: "Solution Management",
  calander: { ...Default },
  no_option: "No Options",

  limit_should_be_more_than_0: "Limit should be more than 0",

  GAMELISTMANAGEMENT: "Game List Management",
  ENVCONTROLLER: "Env Controller Management",
  AGGREGATORMANAGEMENT: "Game Vendor Management",
  new: "New",
  current: "Current",
  deleted: "Deleted",
  game_code: "Game Code",
  game_enabled: "Game Enabled",
  platform: "Platform",
  date_registerd: "Date",

  win_limit_deleted: "Win Limit Deleted!",
  MAX: "MAX",
  current_games: "Current Games",
  deleted_games: "Deleted Games",
  new_games: "New Games",

  //bet history
  warning_massage_1:
    "Simultaneous play of multiple games or games like poker (betting more than once in a single round) may result in a different order and outcome in the display of subsequent balances. The actual user balances are normal,",
  warning_massage_2: "so please consider this in operation.",

  number_of_cancelbets_today: "NUMBER OF CANCEL BETS TODAY",

  USERBETMANAGEMENT: "User Betting Management",

  Round: "Round",
  User: "User",
  Agent: "Agent",

  Game_image_url: "Game image url",

  date_should_not_more_than_one_day:
    "Selected date should not more than 1 days from today!",
  total_amout_recevied: "Total amount received",
  please_enter_valid_vendor: "Please enter valid data!",
  please_enter_valid_data_for_game_type:
    "Please enter valid data for game type!",
  statics: "Statics",
  casino_user_name_is_required: "Casino user name is required",
  agent_name_is_required: "Agent name is required",
  total_users: "number of Total Users",
  number_of_new_agents: "number of new Agents",
  bottom: "Bottom",
  sum: "Total Holding Amount",
  Edit_Game: "Edit Game",

  this_month_win_amount: "This month win amount",
  this_month_bet_count: "This month bet count",
  this_month_profit: "This month profit",
  this_month_bet_amount: "This month bet amount",
  today_win_amount: "Today win amount",
  today_bet_count: "Today bet count",
  today_profit: "Today profit",
  today_bet_amount: "Today bet amount",
  chart: "chart",

  five_million: "50M",
  hundred_million: "100M",
  three_hundred_million: "300M",
  five_hundred_million: "500M",
  one_billion: "1B",

  ALLAGENTREVENUESTAT: "All Agent Revenue",
  PotStatics: "Pot statics",

  potTransactionId:"Transaction ID",
  tradingAgent:"Agent",
  targetAgent:"Target (Agent/User)",
  Transactiontype:"Type",
  prevBalance:"Before Balance",
  transactionAmt:"Amount",
  amountAfter:"After Balance",
  statusTransac:"Status",
  transDate:"Date",

  casino_user_count: "casino user count",

  last_login_date: "last login date",
  disable_time: "Disable Time",
  casino_user_id: "user ID",
  deposit_money: "deposit money",
  widthraw_money: "widthraw money",
  refund: "refund",
  admin_casino_users: "Admin Casino Users",
  sub_agent_pot_history: "Sub Agent Pot history",
  agent_refund: "Agent Refund",
  my: "My",

  Pleased_enter_agent_uid_and_casino_username:
    "Pleased enter Agent uid and casino username",
  please_contact_admin_make_sure_invest_account_metadata_is_available: "Please contact admin.Make sure invest account metadata is available"
};

export default locale;
