import agentRevenueStatisticsConfigs from "./agent-revenue-statistics/agentRevenueStatisticsConfigs";
import potDestributionConfigs from "./pot-destibution-statistics/potDestributionConfigs";
import revenueStatisticsCasinoApp from "./revenue-statistics-casino/revenueStatisticsCasinoConfigs";
import revenueStatisticsCasinoAppAllMonth from "./revenue-statistics-casino-all-month/revenueStatisticsCasinoConfigsAllMonth";
import subAgentRevenueStatisticsConfigs from "./sub-agent-revenue-statistics/subAgentRevenueStatisticsConfigs";
import userRevenueStatisticsConfigs from "./user-revenue-statistics/userRevenueStatisticsConfigs";
import statisticsByGameConfigs from "./statistics-by-game/statisticsByGameConfigs";
import providerStatisticsByGameConfigs from "./provider-statistics-by-game/providerStatisticsByGameConfigs";
import AllAgentListConfigs from "./all-agent-statics/AllAgentListConfigs";

import allAgentRevenueStatisticsApp from "./all-agent-revenue-statistics/allAgentRevenueStatisticsApp";
import allAgentRevenueStatisticsConfigs from "./all-agent-revenue-statistics/allAgentRevenueStatisticsConfigs";
import dailySummaryConfigs from "./daily-summary/dailySummaryConfigs";

const statisticsConfigs = [
  agentRevenueStatisticsConfigs,
  subAgentRevenueStatisticsConfigs,
  potDestributionConfigs,
  revenueStatisticsCasinoApp,
  revenueStatisticsCasinoAppAllMonth,
  userRevenueStatisticsConfigs,
  statisticsByGameConfigs,
  providerStatisticsByGameConfigs,
  allAgentRevenueStatisticsConfigs,
  dailySummaryConfigs,
];

export default statisticsConfigs;
